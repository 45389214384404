import React, {useEffect} from 'react';
import './static/stx-chart.css'
import './App.css';
import './static/font.css';
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import Login from "./Login";
import 'react-toastify/dist/ReactToastify.css';
import EventEmitter from "./EventEmitter";
import Layout from "./page/Layout";
import LeverageContractPlace from "./components/LeverageContractPlace";
import TimeBet from "./components/TimeBet";
import UpDownContract from "./page/UpDownContract";
import KlineLayout from "./components/KlineLayout";
import Profile from "./components/Profile";
import SettingPage from "./components/SettingPage";
import Transaction from "./components/Transaction";
import './util/i18n'
import {useTranslation} from "react-i18next";
import {useRecoilState, useSetRecoilState} from "recoil";
import {langsState, selectLangState} from "./store/system";
import {queryLangs} from "./api";


function App() {
    const navigate = useNavigate()

    const {i18n} = useTranslation()
    const setLangs = useSetRecoilState(langsState)
    const [selectLang,setSelectLang] = useRecoilState(selectLangState)
    const initLangs = async ()=>{
        let data:any = await queryLangs()
        if(data) {
            data = data.data
            if (data && data.length > 0) {
                const item = data[0]
                setSelectLang(item)
            }
            setLangs(data)
        }
    }
    useEffect(() => {
        if(selectLang) {
            i18n.changeLanguage(selectLang.outId)
        }
    }, [selectLang]);
    useEffect(() => {
        initLangs()
    }, []);

    EventEmitter.on("redirect", (router: string) => {
        navigate(router)
    })
    return (
        <Routes>
            <Route>
                    <Route path="/" element={<Navigate to="/trade/time-contract" replace />} />
                    <Route path="/trade" element={<Layout/>}>
                        <Route path="" element={<KlineLayout/>}>
                            <Route path="/trade/leverage-contract" element={<LeverageContractPlace/>}/>
                            <Route path="/trade/time-contract" element={<TimeBet/>}/>
                        </Route>
                        <Route path="/trade/updown-contract" element={<UpDownContract/>}/>
                        <Route path="/trade/profile" element={<Profile/>}/>
                        <Route path="/trade/transaction" element={<Transaction/>}/>
                        <Route path="/trade/bet-history" element={<Transaction/>}/>
                        <Route path="/trade/other" element={<Transaction/>}/>
                        <Route path="/trade/setting" element={<SettingPage/>}/>
                    </Route>
                    <Route path="/login" element={<Login/>}/>
            </Route>
        </Routes>
    );
}

export default App;
