const UpDownIcon = ({type}: { type:string}) => {
    return  <svg xmlns="http://www.w3.org/2000/svg" className={`${type==='up'?'':'rotate-180'} text-${type} UpDownIcon`} width="23" height="24"
    viewBox="0 0 23 24" fill="none">
<path
    d="M11.3998 6.24695L5.60645 12.0402L6.92637 13.3602L11.3998 8.88681L15.8731 13.3602L17.1931 12.0402L11.3998 6.24695ZM11.3998 11.5202L5.60645 17.3134L6.92637 18.6335L11.3998 14.1601L15.8731 18.6335L17.1931 17.3134L11.3998 11.5202Z"
    fill="currentColor"></path>
</svg>
}
export default UpDownIcon

