import {useRecoilState, useSetRecoilState} from "recoil";
import {accountState} from "../store/user";
import {get_account_info, get_cryptos, menus, queryI18n, queryLangs} from "../api";
import React, {createRef, useEffect, useRef, useState} from "react";
import {useMediaQuery} from "react-responsive";
import Menu from "../components/Menu";
import TradeOrder from "../components/TradeOrder";
import MobileMenu from "../components/MobileMenu";
import {Outlet, useLocation} from "react-router-dom";
import {gameState, menuIndexState, menuState} from "../store/game";
import RiskWarning from "../components/RiskWarning";
import PageHeader from "../components/PageHeader";
import WalletPanel from "../components/WalletPanel";
import Notifications from "../components/Notifications";
import {
    cryptoFlagState,
    langsState,
    langVisibleState,
    leverageState,
    notificationState,
    selectLangState,
    walletPanelState
} from "../store/system";
import LeverageWork from "../components/LeverageWork";
import LangDialog from "../components/LangDialog";
import {cryptosState, selectCryptoState} from "../store/crypto";
import {useAfterUserInfo} from "../hooks";
import {useTranslation} from "react-i18next";

const Layout = () => {
    const {t} = useTranslation()
    const setAccountState = useSetRecoilState(accountState)
    const [riskVisible, setRiskVisible] = useState(false)
    const setCryptos = useSetRecoilState(cryptosState)
    const [selectCrypto, setSelectCrypto] = useRecoilState(selectCryptoState)
    const [, setCryptoFlag] = useRecoilState(cryptoFlagState)
    const [notificationVisible, setNotificationVisible] = useRecoilState(notificationState)
    const [leverageWorkVisible, setLeverageWorkVisible] = useRecoilState(leverageState)
    const [walletPanelVisible, setWalletPanelVisible] = useRecoilState(walletPanelState)
    const [, setMenuIndex] = useRecoilState(menuIndexState)
    const setGameState = useSetRecoilState(gameState)
    const [menuData, setMenuData] = useRecoilState(menuState)
    const [langVisible, setLangVisible] = useRecoilState(langVisibleState)
    const initData = async () => {
        const {data} = await get_account_info()
        setAccountState(data)
    }

    const initCryptos = async () => {
        const {data} = await get_cryptos()
        setCryptos(data)
        if (selectCrypto == null && data != null && data.length > 0) {
            setSelectCrypto(data[0])
        }
        setCryptoFlag(true)
    }
    const location = useLocation()
    const queryGames = async () => {
        const {data} = await menus()
        setMenuData(data)
    }
    const matchFocus = () => {
        if (menuData?.length > 0) {
            const index = menuData.findIndex(t => t.url === location.pathname)
            if (index > -1) {
                const outId = menuData[index].gameId
                setGameState({outId})
            }
            setMenuIndex(location.pathname)
        }
    }
    useEffect(() => {
        matchFocus()
    }, [location, menuData]);
    const isMounted = useRef(false);
    useAfterUserInfo(() => {
        queryGames().then()
        initCryptos().then()
        get_cryptos().then()
        isMounted.current = true;
        initData().then()
        return function () {
            if (isMounted.current) {
            }
        }
    }, []);
    useEffect(() => {
        setRiskVisible(true)
    }, []);
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1366px)'})
    const rootRef: any = createRef()
    return <div id="trade-app-root" ref={rootRef} className="trade-app-root font-primary leading-normal text-primary">
        <div className="flex flex-col s768:w-screen s768:h-screen s768:overflow-hidden">
            <PageHeader/>
            <div className="relative flex-1 flex overflow-hidden">
                {!isTabletOrMobile ? <Menu/> : <></>}
                <div className="flex-1 flex flex-col overflow-hidden">
                    <div className="flex flex-1 overflow-hidden">
                        <div className="flex-1 overflow-auto">
                            <div className="flex-1 bg-layer2 overflow-x-hidden p-4">
                                <div className="flex flex-col flex-1 gap-3">
                                    <Outlet/>
                                    <TradeOrder/>
                                </div>
                            </div>
                            <footer className="py-18 px-12 bg-layer2">
                                <div className="max-w-[1140px] s1920:max-w-[1600px] mx-auto">
                                    <div
                                        className="flex s768:justify-center s1024:justify-between flex-wrap gap-4 mb-8">
                                        <div className="flex flex-col gap-2.5">
                                            <div
                                                className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">Transaction
                                                {t('overview')}
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Trading
                                                {t('platform')}
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Product
                                                {t('description')}
                                            </div>
                                            <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">User
                                                Agreement &amp; Terms
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">AML &amp; KYC
                                                Policy
                                                {t('policy')}
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Privacy
                                                Policy
                                                {t('privacy_policy')}
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2.5">
                                            <div
                                                className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">
                                                {t('service_guide')}
                                                Service
                                                Guide
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">
                                                {t('detrade_overview')}
                                                DeTrade
                                                Overview
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Deposits
                                                And
                                                Withdrawals
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Partner
                                                Program
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Contact
                                                Us
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2.5">
                                            <div
                                                className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">Beginners
                                            </div>
                                            <div className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">FAQ
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Quick
                                                Demo
                                            </div>
                                        </div>
                                        <div className="flex flex-col gap-2.5">
                                            <div
                                                className="font-500 text-14 leading-5 px-4 py-2.5 mb-4 bg-white/10 rounded-2">Existing
                                                Customers
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Login
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Forgot
                                                the
                                                Password
                                            </div>
                                            <div
                                                className="text-14 text-[#C9CFDD] hover:text-brand cursor-pointer">Personal
                                                Center
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                className="mb-2.5 font-500 text-14 leading-5 px-4 py-2.5 bg-white/10 rounded-2">Use
                                                your phone to trade quickly.
                                            </div>
                                            <div className="flex items-center justify-around bg-[#0F1725] rounded-2">
                                                <img
                                                    src="/static/media/footer_mobile_tips_pc.d6e5a0e4772c25ff0d07.png"
                                                    className="w-24" alt="mobile"/>
                                                <button
                                                    className="inline-flex items-center justify-center max-w-full active:scale-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 px-4 py-2 text-14 rounded-full">Learn
                                                    More
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col gap-8">
                                        <div className="h-px bg-white/10"></div>
                                        <div className="text-12 text-secondary"><p className="p-1">Brokerage Products
                                            and
                                            Services
                                            offered by Nexus Play LLC.</p><p className="p-1">Nexus Play LLC, a Brokerage
                                            Company
                                            authorised and regulated by the Mwali International Services Authority
                                            (M.I.S.A.) under the License #BFX2024137.</p><p className="p-1">Nexus Play
                                            LLC
                                            is a
                                            Brokerage that provides self-directed investors with brokerage services, and
                                            does not make recommendations or offer investment, financial, legal or tax
                                            advice. Online trading carries inherent risks, including fluctuations in
                                            system
                                            response and access times due to market conditions, system performance, and
                                            other factors. Before trading, consult an independent, licensed financial
                                            advisor and ensure you have the necessary risk appetite, experience, and
                                            knowledge. Under no circumstances shall Nexus Play LLC have any liability to
                                            any
                                            person or entity for any direct, indirect, special, consequential or
                                            incidental
                                            damages whatsoever. </p><p className="p-1">The products and services offered
                                            by
                                            Nexus Play LLC are not intended for residents of the United States, European
                                            Union, or any other jurisdictions where such services and products are
                                            prohibited by law. Nexus Play LLC does not solicit business or provide
                                            brokerage
                                            services to individuals or entities in these regions. It is the
                                            responsibility
                                            of potential clients to be aware of and comply with any applicable laws and
                                            regulations in their respective jurisdictions before engaging in online
                                            trading.
                                            By accessing this website and utilizing our services, you confirm that you
                                            are
                                            not a resident of the United States, European Union, or any other
                                            jurisdiction
                                            where our services are restricted.</p><p className="p-1">All brokerage
                                            activity
                                            on
                                            this website is provided by Nexus Play LLC.</p><p className="p-1">Copyright
                                            ©
                                            2024
                                            Nexus Play LLC., All rights reserved.</p></div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                    {isTabletOrMobile ? <MobileMenu/> : <></>}
                </div>
            </div>
        </div>
        <div id="custom-portal-root">
        </div>
        <RiskWarning visible={riskVisible} onCancel={() => setRiskVisible(false)}/>
        <WalletPanel visible={walletPanelVisible} onCancel={() => {
            setWalletPanelVisible(false)
        }}/>
        <Notifications visible={notificationVisible} onCancel={() => {
            setNotificationVisible(false)
        }}/>
        <LeverageWork visible={leverageWorkVisible} onCancel={() => {
            setLeverageWorkVisible(false)
        }}/>
        <LangDialog visible={langVisible} onCancel={() => {
            setLangVisible(false)
        }}/>
    </div>
}
export default Layout
