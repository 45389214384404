import {Popover, PopoverButton, PopoverPanel} from "@headlessui/react";
import {listWallets, resetWallet} from "../api";
import {useRecoilState} from "recoil";
import {currentWalletState, WalletItem, walletRequestIdState, walletState} from "../store/user";
import {useAfterUserInfo} from "../hooks";
import {useTranslation} from "react-i18next";


const Wallet = () => {
    const {t} = useTranslation()
    const [wallets, setWallets] = useRecoilState(walletState)
    const [currentWallet, setCurrentWallet] = useRecoilState(currentWalletState)
    const [walletRequestId, setWalletRequestId] = useRecoilState(walletRequestIdState)
    const queryWallets = async () => {
        const {data} = await listWallets()
        if (data && data.length > 0 && !currentWallet) {
            setCurrentWallet(data[0])
        }
        setWallets(data)
    }

    const selectWallet = (item: WalletItem) => {
        setCurrentWallet(item)
    }

    useAfterUserInfo(() => {
        queryWallets().then()
    }, [walletRequestId]);
    return <div
        className="flex items-center gap-1 bg-layer3 pl-3 pr-1 py-1 border-2 border-input rounded-2 w-[184px] s768:w-60 ml-auto mr-3 s768:mr-auto">
        <Popover className="relative flex-1">
            <PopoverButton
                className="outline-none bg-transparent active:translate-y-px hover:enabled:brightness-95 flex items-center gap-1 w-full">
                <span
                    className="block overflow-hidden size-4 s768:size-5 rounded-full lazy-load-image-background  lazy-load-image-loaded"><img
                    src={currentWallet?.currency?.icon}
                    className="w-full h-full object-cover"/></span>
                <div
                    className="text-12 s768:text-16 text-left font-600 w-24 truncate flex-1">{currentWallet?.balance}</div>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                     className="rotate-90 size-4 s768:size-6 text-[#B3BEC1]">
                    <path d="M10 7L15 12L10 17" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
                          strokeLinejoin="round"></path>
                </svg>
                {currentWallet?.currency?.testFlag ? <button
                        onClick={async (e) => {
                            await resetWallet(currentWallet?.currency?.outId)
                            setWalletRequestId(walletRequestId + 1)
                            e.stopPropagation()
                            e.preventDefault()
                        }}
                        className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 px-4 py-2 capitalize w-14 s768:w-18 h-7 s768:h-9 text-14 rounded-1.5">
                        {t('reset')}
                    </button> :
                    <button
                        className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 px-4 py-2 capitalize w-14 s768:w-18 h-7 s768:h-9 text-14 rounded-1.5">
                        {t('wallet')}
                    </button>}
            </PopoverButton>
            <PopoverPanel anchor="bottom"
                          className="absolute z-40 bg-layer4 shadow-popup rounded-2 text-secondary overflow-auto p-2 s768:p-3 max-h-100 w-72 s768:w-96 -left-4 mt-5 s768:mt-7">
                {({close}) =>
                    <>
                        <div className="relative mt-2 rounded shadow-sm">
                            <svg className="absolute left-2 top-1/2 -translate-y-1/2" xmlns="http://www.w3.org/2000/svg"
                                 width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                    d="M12.0213 11.078L14.8765 13.9332L13.9337 14.876L11.0785 12.0208C10.0519 12.8422 8.74999 13.3335 7.33399 13.3335C4.02198 13.3335 1.33398 10.6455 1.33398 7.3335C1.33398 4.0215 4.02198 1.3335 7.33399 1.3335C10.646 1.3335 13.334 4.0215 13.334 7.3335C13.334 8.7495 12.8427 10.0514 12.0213 11.078ZM10.6838 10.5834C11.499 9.74323 12.0007 8.59723 12.0007 7.3335C12.0007 4.75516 9.91232 2.66683 7.33399 2.66683C4.75565 2.66683 2.66732 4.75516 2.66732 7.3335C2.66732 9.91183 4.75565 12.0002 7.33399 12.0002C8.59772 12.0002 9.74372 11.4985 10.5839 10.6833L10.6838 10.5834Z"
                                    fill="#ADB1B8"></path>
                            </svg>
                            <input type="text"
                                   className="flex w-full px-2 rounded-2 placeholder:text-quarterary outline-none shadow-sm focus:ring focus:ring-brand transition-all disabled:cursor-not-allowed disabled:opacity-50 h-8 bg-transparent border border-input s768:h-10 pl-7 mb-3 text-12 s768:text-14"
                                   autoComplete="off" placeholder="Search" value="" readOnly={true}/></div>
                        {wallets?.map((item: WalletItem, index) => <div onClick={() => {
                            selectWallet(item);
                            close()
                        }}
                                                                        className="text-primary"
                                                                        key={`wallet-${index}`}>
                            <div
                                className={`flex items-center justify-between cursor-pointer px-3 py-2 mb-2 last:mb-0 rounded-2 hover:bg-layer5 ${currentWallet?.currency.outId === item.currency?.outId ? 'bg-layer5' : ''}`}>
                                <div className="flex items-center gap-3"><span
                                    className="block overflow-hidden w-7 h-7 rounded-full lazy-load-image-background  lazy-load-image-loaded">
                            <img
                                alt=""
                                src={item.currency?.icon}
                                className="w-full h-full object-cover"/></span>
                                    <div className="text-12 s768:text-16 font-600">{item.currency?.coin}</div>
                                    {item.currency?.testFlag ?
                                        <div
                                            className="text-12 text-success px-2.5 py-1 bg-success/10 rounded-2">{t('demo')}
                                        </div> : <></>}
                                </div>
                                <div className="text-right">
                                    <div className="text-12 s768:text-16 font-600">{item.balance}</div>
                                    <div className="text-12 text-secondary">USD 0</div>
                                </div>
                            </div>
                        </div>)}</>}
            </PopoverPanel>
        </Popover>
    </div>
}

export default Wallet
