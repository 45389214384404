import React, {useEffect, useRef, useState} from "react";
import {Input, Popover, PopoverButton, PopoverPanel, Tab, TabGroup, TabList} from "@headlessui/react";
import SendOrder from "./SendOrder";
import {useRecoilValue, useSetRecoilState} from "recoil";
import LeverageInfo from "./LeverageInfo";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import {useMediaQuery} from "react-responsive";
import {selectCryptoState} from "../store/crypto";
import {gameTypeState} from "../store/game";
import QuickBetOrder from "./QuickBetOrder";

const LeverageContractPlace = () => {
    const tabs = [{title: 'MANUAL', id: 0}, {title: 'AUTO', id: 1}]
    const [focus, setFocus] = useState({id: 0})
    const [resultType, setResultType] = useState(0)
    const [params] = useState({})
    const selectCrypto = useRecoilValue(selectCryptoState)
    const setGameType = useSetRecoilState(gameTypeState)
    useEffect(() => {
        setGameType("Leverage")
    }, []);
    const sendOrderRef = useRef<{ join: Function }>();
    const [amount, setAmount] = useState(0)
    const [autoParams, setAutoParams] = useState({takeAtPrice: 0, takeAtProfit: 0, closeAtPrice: 0, closeAtLoss: 0})
    const [multiplier, setMultiplier] = useState(10)
    const [open, setOpen] = useState(false)
    const flag = focus.id === 1
    const actionStyle = resultType === 1 ? {
        background: 'linear-gradient(to right, rgb(var(--up)), #9FE871)',
        boxShadow: '0px 0px 12px 0px rgb(var(--up) / 0.3), 0px -2px 0px 0px #1DCA6A inset'
    } : {
        background: 'linear-gradient(to right, rgb(var(--down)), #B43B38)',
        boxShadow: 'rgb(180, 59, 56) 0px -2px 0px 0px inset'
    }
    const bustPrice = (selectCrypto?.close || 0) - (resultType === 0 ? -1 : 1) * ((selectCrypto?.close || 0) / multiplier);
    const changeMultiplier = (value: number | number[]) => {
        setMultiplier(+value)
    }
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 768px)'})
    const orderPanel = <div
        className="s1024:ml-3 min-w-fit rounded-4 p-4 bg-layer4 h-fit s1024:w-[471px] s1366:w-[500px] s1440:w-[526px]">
        <div className="flex-1 mb-2">
            <TabGroup>
                <TabList
                    className="text-12 text-secondary p-1 rounded-3 flex items-center font-600 sticky top-0 bg-layer3 light:darkness">
                    {
                        tabs.map((item, key) => (<Tab key={item.title} onClick={() => {
                            setFocus(item)
                        }}
                                                      className={`${focus.id === item.id ? 'text-primary font-700' : ''} relative outline-none py-3 transition-colors duration-300 z-20 flex-1 px-0 leading-none`}>
                            {focus.id === item.id ? <div className="absolute inset-0 z-0 rounded-2 bg-tab_selected"
                                                         style={{
                                                             transform: 'none',
                                                             transformOrigin: '50% 50% 0px',
                                                             opacity: 1
                                                         }}/> : <></>}
                            <div className="relative z-10">{item.title}</div>
                        </Tab>))
                    }
                </TabList>
            </TabGroup>
        </div>
        <div className="flex flex-col s768:flex-row gap-2 flex-nowrap w-full mb-5">
            <div className="flex-1">
                <div className="mb-3">
                    <div className="w-full h-12 flex items-center text-primary text-13">Will The Price go UP OR DOWN
                    </div>
                    <div
                        className="relative flex h-11 w-full rounded-2 overflow-hidden -space-x-2 text-primary text-12">
                        <button onClick={() => setResultType(0)}
                                className={`inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none 
                            whitespace-nowrap font-500 disabled:pointer-events-none disabled:opacity-50 bg-transparent px-4 py-2
                             text-12 relative h-full flex-1 transition-colors shadow-none 
                             rounded-none text-white origin-left gap-3 ${resultType === 0 ? '' : 'opacity-20'}`}>
                            <div
                                className="skew-x-[23deg] absolute inset-0 z-0 rounded-tr bg-down origin-bottom-right"></div>
                            <div className="relative text-16 font-600">DOWN</div>

                        </button>
                        <button onClick={() => setResultType(1)}
                                className={`inline-flex items-center justify-center max-w-full active:translate-y-px 
                            hover:brightness-95 outline-none whitespace-nowrap font-500 disabled:pointer-events-none 
                            disabled:opacity-50 bg-transparent px-4 py-2 text-12 relative h-full flex-1 transition-colors 
                            shadow-none rounded-none text-white origin-right flex-row-reverse gap-3 ${resultType === 1 ? '' : 'opacity-20'}`}>
                            <div
                                className="skew-x-[23deg] absolute inset-0 z-0 rounded-bl bg-up origin-top-left"></div>
                            <div className="relative text-16 font-600">UP</div>
                            <svg xmlns="http://www.w3.org/2000/svg" className="rotate-180 relative size-6"
                                 width="38"
                                 height="38" viewBox="0 0 38 38" fill="none">
                                <path
                                    d="M33.4286 14.2063L26.9648 20.6701L21.5916 15.295L15.5933 21.2933L19 24.7L9.50001 24.7L9.50001 15.2L12.9067 18.6067L21.5916 9.92181L26.9648 15.295L31.6673 10.5944C29.5328 7.38178 26.2568 5.10076 22.5032 4.21357C18.7495 3.32638 14.799 3.89939 11.452 5.81648C8.10514 7.73357 5.61222 10.8514 4.47845 14.538C3.34468 18.2247 3.65486 22.2045 5.34615 25.671C7.03743 29.1375 9.98331 31.8314 13.5868 33.2068C17.1903 34.5822 21.182 34.5362 24.7528 33.0781C28.3237 31.6201 31.2067 28.859 32.8177 25.3545C34.4286 21.8499 34.647 17.864 33.4286 14.2044L33.4286 14.2063ZM36.3508 11.2461L36.3698 11.2651L36.3622 11.2727C37.4453 13.7047 38.0034 16.3377 38 19C38 29.4937 29.4937 38 19 38C8.50631 38 2.36318e-05 29.4937 2.45492e-05 19C2.54666e-05 8.50631 8.50631 2.03098e-05 19 2.12272e-05C26.733 2.19032e-05 33.383 4.61702 36.3508 11.2461Z"
                                    fill="currentColor" fillOpacity="0.75"></path>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="h-10 text-primary flex items-center text-14 font-600"><span>WAGER</span></div>
                <div className="flex items-center bg-layer2 rounded-3 gap-1 p-1 border border-input relative"><label
                    htmlFor="amount-input-up-down"
                    className="flex-center w-6 h-6 ml-1.5 rounded-full overflow-hidden pointer-events-none"
                    style={{
                        backgroundImage: 'url(https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC@3x.png)',
                        backgroundSize: 'contain'
                    }}></label><input
                    value={amount}
                    onChange={e => setAmount(+e.target.value)}
                    className="px-2 text-13 font-700 rounded placeholder:text-quarterary foczus:outline-none focus:outline-background-brand disabled:cursor-not-allowed flex-1 w-0 h-8 s1366:h-10 text-primary bg-inherit"
                    type="text" inputMode="decimal" autoComplete="off" id="amount-input-up-down"/>
                    <button
                        className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 w-8 s768:w-15 h-8 s1366:h-10 shrink-0 bg-layer7 rounded-2 text-13 s1366:text-14 text-primary font-500 select-none">1/2
                    </button>
                    <button
                        className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 w-8 s768:w-15 h-8 s1366:h-10 shrink-0 bg-layer7 rounded-2 text-13 s1366:text-14 text-primary font-500 select-none">x2
                    </button>
                </div>
            </div>
            {flag ? <div
                className="s1440:w-60 s1366:w-[214px] s768:w-[214px] overflow-hidden rounded-2 pt-1 bg-layer5 light:bg-layer7">
                <div className="px-2">
                    <div className="text-primary text-13 font-600 h-11 flex items-center"><span>TAKE PROFIT AT PRICE / PROFIT</span>
                    </div>
                    <div className="text-primary flex flex-wrap gap-2">
                        <Input
                            onChange={(e) => {
                                setAutoParams({...autoParams, takeAtPrice: +e.target.value})
                            }}
                            className="text-primary h-10 bg-layer3 text-13 font-700 placeholder:text-quarterary focus:outline-none focus:outline-background-brand disabled:cursor-not-allowed focus:!border-up flex-1 w-0 px-2 border border-input rounded-2 placeholder:font-500"
                            type="text" inputMode="decimal" autoComplete="off"
                            placeholder="Price"
                            value={autoParams.takeAtPrice}
                        />
                        <div
                            className="h-10 focus-within:!border-up flex items-center flex-1 bg-layer3 rounded-2 placeholder:font-500 px-2 border border-input">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"
                                 fill="none" className="w-4 h-4 text-up">
                                <path
                                    d="M7.83398 7.3335V3.3335H9.16732V7.3335H13.1673V8.66683H9.16732V12.6668H7.83398V8.66683H3.83398V7.3335H7.83398Z"
                                    fill="#0BD8B6"></path>
                            </svg>
                            <span>$</span>
                            <Input
                                onChange={(e) => {
                                    setAmount(+e.target.value)
                                }}
                                className="px-2 bg-layer3 text-13 font-700 rounded placeholder:text-quarterary focus:outline-none focus:outline-background-brand disabled:cursor-not-allowed h-9.5 w-full !border-none"/>
                        </div>
                        <div className="w-full shrink-0 text-down font-500 text-12"></div>
                    </div>
                    <div className="text-primary text-13 font-600 h-11 flex items-center">CLOSE BET AT PRICE / LOSS
                    </div>
                    <div className="text-primary flex flex-wrap gap-2">
                        <Input
                            onChange={(e) => {
                                setAutoParams({...autoParams, closeAtPrice: +e.target.value})
                            }}
                            className="h-10 bg-layer3 text-13 font-700 placeholder:text-quarterary focus:outline-none focus:outline-background-brand disabled:cursor-not-allowed focus:!border-down flex-1 w-0 px-2 border border-input rounded-2 placeholder:font-500"
                            type="text" inputMode="decimal" autoComplete="off" placeholder="Price"/>
                        <div
                            className="h-10 focus-within:!border-down flex items-center flex-1 bg-layer3 rounded-2 placeholder:font-500 px-2 border border-input">
                            <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16"
                                 fill="none" className="w-4 h-4 text-down">
                                <path d="M3.83398 7.3335V8.66683H13.1673V7.3335H3.83398Z" fill="#FF8589"></path>
                            </svg>
                            <span>$</span><Input
                            onChange={(e) => {
                                setAmount(+e.target.value)
                            }}
                            className="px-2 bg-layer3 text-13 font-700 rounded placeholder:text-quarterary focus:outline-none focus:outline-background-brand disabled:cursor-not-allowed h-9.5 w-full !border-none"
                            type="text" inputMode="decimal" autoComplete="off" placeholder="Profit"/></div>
                        <div className="w-full shrink-0 text-down font-500 text-12"></div>
                    </div>
                </div>
            </div> : <></>}
        </div>
        <div className="flex flex-col w-full">
            <div className="flex items-end justify-between mb-3">
                <div className="flex-1 mr-5">
                    <div className="text-primary text-13 font-600 mb-2 capitalize">PAYOUT MULTIPLIER</div>
                    <div className="relative flex items-center h-12 p-1 bg-layer2 rounded-3 border border-input">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                             fill="none">
                            <path
                                d="M10.0006 8.82208L14.1253 4.69727L15.3038 5.87577L11.1791 10.0006L15.3038 14.1253L14.1253 15.3038L10.0006 11.1791L5.87577 15.3038L4.69727 14.1253L8.82208 10.0006L4.69727 5.87577L5.87577 4.69727L10.0006 8.82208Z"
                                fill="#ADB1B8"></path>
                        </svg>
                        <input type="tel"
                               onChange={(e) => setMultiplier(+e.target.value)}
                               className="placeholder:text-quarterary focus:ring-brand transition-all disabled:cursor-not-allowed disabled:opacity-50 block w-25 s768:w-auto flex-1 h-full rounded border-0 focus:ring-0 px-2 bg-inherit text-primary outline-none shadow-none"
                               autoComplete="off" value={multiplier}/></div>
                </div>
                <div className="flex-1">
                    <div className="text-secondary text-12">Bust Price</div>
                    <div className="text-primary text-13 font-600">{bustPrice?.toLocaleString() || 0}</div>
                </div>
            </div>
            <Slider
                onChange={changeMultiplier}
                max={1000}
                styles={{
                    rail: {
                        background: "linear-gradient(90deg, rgb(134, 244, 84) 0%, rgb(255, 209, 90) 50%, rgb(255, 106, 106) 100%)",
                        height: '8px'
                    },
                    handle: {
                        // left: "24.8248 %",
                        transform: 'translateX(-50 %)',
                        width: '16px',
                        height: '16px',
                        borderRadius: '6px', border: '1px solid rgba(56, 106, 255, 0.37)',
                        marginTop: '-4px',
                        opacity: 1
                    }
                }}/>
            <div className="my-3 text-12 text-secondary flex justify-between items-center">
                <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                            d="M10.0006 8.82208L14.1253 4.69727L15.3038 5.87577L11.1791 10.0006L15.3038 14.1253L14.1253 15.3038L10.0006 11.1791L5.87577 15.3038L4.69727 14.1253L8.82208 10.0006L4.69727 5.87577L5.87577 4.69727L10.0006 8.82208Z"
                            fill="#ADB1B8"></path>
                    </svg>
                    <span>1</span><span className="ml-1 text-up">Safe</span></div>
                <div className="flex items-center"><span className="text-down">Wild</span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path
                            d="M10.0006 8.82208L14.1253 4.69727L15.3038 5.87577L11.1791 10.0006L15.3038 14.1253L14.1253 15.3038L10.0006 11.1791L5.87577 15.3038L4.69727 14.1253L8.82208 10.0006L4.69727 5.87577L5.87577 4.69727L10.0006 8.82208Z"
                            fill="#ADB1B8"></path>
                    </svg>
                    <span>1000</span></div>
            </div>
            <SendOrder amount={amount} params={params} ref={sendOrderRef}/>
            <button
                className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-transparent px-4 py-2 rounded-2 text-18 font-800 text-black h-12 capitalize"
                style={actionStyle} onClick={() => {
                sendOrderRef.current?.join({type: focus.id, symbol: selectCrypto?.symbol})
            }}>place bet
            </button>
            <div className="text-14 text-secondary cursor-pointer flex items-center gap-1 my-2 w-fit">
                <svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                     className="rotate-180 w-3 h-3 UpDownIcon">
                    <path d="M325.456471 862.280661" fill="currentColor"></path>
                    <path d="M882.057788 862.280661" fill="currentColor"></path>
                    <path d="M236.028491 877.160382" fill="currentColor"></path>
                    <path d="M960.132455 877.160382" fill="currentColor"></path>
                    <path d="M63.683483 788.736998" fill="currentColor"></path>
                    <path d="M958.469023 788.736998" fill="currentColor"></path>
                    <path d="M64.77753 858.792098" fill="currentColor"></path>
                    <path
                        d="M861.417121 738.727375c41.604731 0 65.233383-54.963795 34.928639-85.258218L547.071415 304.191372c-20.029996-20.031716-49.822121-20.031716-69.853837 0L127.955275 653.469157c-31.085714 31.073673-5.136514 85.258218 35.441258 85.258218L861.417121 738.727375 861.417121 738.727375z"
                        fill="currentColor"></path>
                    <path d="M959.523505 858.792098" fill="currentColor"></path>
                </svg>
                Show Fee Options
            </div>
            <div className="flex justify-between items-center mt-2 relative z-10 bg-layer4">
                <button
                    className="inline-flex items-center justify-center max-w-full rounded-2 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap font-500 disabled:pointer-events-none disabled:opacity-50 px-4 py-2 text-12 bg-layer5 light:bg-layer7 h-10 text-secondary">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                         className="mr-3">
                        <path
                            d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z"
                            fill="currentColor"></path>
                        <path
                            d="M16 13C15.44 13 15 12.55 15 12C15 11.45 15.45 11 16 11C16.55 11 17 11.45 17 12C17 12.55 16.56 13 16 13Z"
                            fill="currentColor"></path>
                        <path
                            d="M8 13C7.44 13 7 12.55 7 12C7 11.45 7.45 11 8 11C8.55 11 9 11.45 9 12C9 12.55 8.56 13 8 13Z"
                            fill="currentColor"></path>
                    </svg>
                    <span>Entrust an order</span></button>
                <div className="flex items-center gap-2">
                    <div className="relative inline-flex cursor-pointer">
                        <Popover className="relative">
                            <PopoverButton onClick={() => setOpen(true)}
                                           className="tems-center justify-center max-w-full rounded-2 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap font-500 disabled:pointer-events-none disabled:opacity-50 text-12 p-2 size-10 flex-center text-secondary bg-layer5 light:bg-layer7">
                                <svg className="size-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                     viewBox="0 0 24 24" fill="currentColor">
                                    <path
                                        d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20ZM11 15H13V17H11V15ZM13 13.3551V14H11V12.5C11 11.9477 11.4477 11.5 12 11.5C12.8284 11.5 13.5 10.8284 13.5 10C13.5 9.17157 12.8284 8.5 12 8.5C11.2723 8.5 10.6656 9.01823 10.5288 9.70577L8.56731 9.31346C8.88637 7.70919 10.302 6.5 12 6.5C13.933 6.5 15.5 8.067 15.5 10C15.5 11.5855 14.4457 12.9248 13 13.3551Z"
                                        fill="currentColor"></path>
                                </svg>
                            </PopoverButton>
                            <PopoverPanel data-open={open}>
                                <LeverageInfo/>
                            </PopoverPanel>
                            {/*// className="absolute z-40 p-3 bg-layer5 light:bg-layer7 rounded-2 cursor-text select-text shadow text-12 text-primary font-300 right-full top-1/2 -translate-y-1/2 mr-1 w-50">*/}
                        </Popover>
                    </div>
                </div>
            </div>
        </div>
    </div>
    const [showOrder, setShowOrder] = useState(false)
    const renderOrderPanel = () => {
        if (isTabletOrMobile) {
            if (!showOrder) {
                return <></>
            }
            return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                        onClick={() => {
                            setShowOrder(false)
                        }}
                        style={{left: '0px', opacity: 1}}>
                <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
                    <div onClick={(e) => {
                        e.stopPropagation();
                    }}
                         className="flex flex-col gap-1.5 absolute z-20 bottom-0 inset-x-0 w-full bg-layer2 shadow-popup rounded-t-3"
                         style={{transform: 'translateY(0%) translateZ(0px)'}}>
                        {orderPanel}
                    </div>
                </div>
            </div>
        }
        return orderPanel
    }
    return <>
        {isTabletOrMobile &&
            <>
                <div className="flex items-center justify-between pl-3" style={{left: '0px', opacity: 1}}>
                    <div className="text-12 font-600">QUICK ACTIVE BETS</div>
                    <button
                        onClick={() => {
                            setShowOrder(true)
                        }}
                        className="inline-flex items-center justify-center max-w-full active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap capitalize disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand text-12 py-1 px-1.5 h-7 rounded-1.5 font-700 w-18">
                        New Bet
                    </button>
                </div>
                <QuickBetOrder/>
            </>}
        {renderOrderPanel()}
    </>
}
export default LeverageContractPlace
