import {useEffect} from 'react';
import {useRecoilValue} from "recoil";
import {userInfoState} from "../store/user";

export const useAfterUserInfo = (callback: Function, depends: any[]) => {
    const userInfo = useRecoilValue(userInfoState)
    useEffect(() => {
        if (userInfo && userInfo.login) {
            callback()
        }
    }, [userInfo.login, ...depends]);
}

