import {DialogProps} from "../constant/fields";
import Close from "./Close";
import {useRecoilState, useRecoilValue} from "recoil";
import {langsState, selectLangState} from "../store/system";



const LangDialog = (props: DialogProps) => {
    const langs = useRecoilValue(langsState)
    const [selectLang, setSelectLang] = useRecoilState(selectLangState)
    return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                style={{left: '0px', opacity: 1, display: props.visible ? 'block' : 'none'}}>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div className="relative mx-5 py-6 bg-layer2 rounded-3 overflow-hidden w-[400px]">
                <div className="px-6 pb-5 text-16 s768:text-20 font-500 text-primary capitalize">language</div>
                <Close onCancel={props.onCancel}/>
                <div
                    className="px-6 pb-2 max-h-[60vh] overscroll-none no-scrollbar text-secondary text-12 font-500 overflow-hidden h-100">
                    <div className="h-full overflow-y-auto">
                        <div className="sticky top-0 z-10 bg-inherit mb-2">
                            <div className="relative">
                                <svg className="absolute left-2 top-1/2 -translate-y-1/2"
                                     xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                     fill="none">
                                    <path
                                        d="M12.0213 11.078L14.8765 13.9332L13.9337 14.876L11.0785 12.0208C10.0519 12.8422 8.74999 13.3335 7.33399 13.3335C4.02198 13.3335 1.33398 10.6455 1.33398 7.3335C1.33398 4.0215 4.02198 1.3335 7.33399 1.3335C10.646 1.3335 13.334 4.0215 13.334 7.3335C13.334 8.7495 12.8427 10.0514 12.0213 11.078ZM10.6838 10.5834C11.499 9.74323 12.0007 8.59723 12.0007 7.3335C12.0007 4.75516 9.91232 2.66683 7.33399 2.66683C4.75565 2.66683 2.66732 4.75516 2.66732 7.3335C2.66732 9.91183 4.75565 12.0002 7.33399 12.0002C8.59772 12.0002 9.74372 11.4985 10.5839 10.6833L10.6838 10.5834Z"
                                        fill="#ADB1B8"></path>
                                </svg>
                                <input type="text"
                                       className="flex w-full px-2 rounded-2 placeholder:text-quarterary outline-none shadow-sm focus:ring focus:ring-brand transition-all disabled:cursor-not-allowed disabled:opacity-50 bg-layer4 h-8 mbq-3 pl-7 text-12"
                                       autoComplete="off" placeholder="Search" value=""/></div>
                        </div>
                        <div className="text-14">
                            {langs.map((item, key) => <div key={`${key}-lang`}
                                                               onClick={()=>{setSelectLang(item)}}
                                                               className="flex items-center justify-between transition-all cursor-pointer text-primary rounded h-9 px-3 py-1.5 hover:bg-layer5 bg-layer5 font-600">
                                <div>{item.name}</div>
                                {selectLang?.name===item.name ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"
                                         fill="none" className="size-4 opacity-100">
                                        <path
                                            d="M0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7Z"
                                            fill="#24EE89"></path>
                                        <path
                                            d="M9.80117 6.99922C9.80117 8.54562 8.54757 9.79922 7.00117 9.79922C5.45477 9.79922 4.20117 8.54562 4.20117 6.99922C4.20117 5.45282 5.45477 4.19922 7.00117 4.19922C8.54757 4.19922 9.80117 5.45282 9.80117 6.99922Z"
                                            fill="#1E2121"></path>
                                    </svg> : <></>}
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default LangDialog
