import {useState} from "react";
import Close from "./Close";

const RiskWarning = ({visible, onCancel}: { visible: boolean, onCancel: Function }) => {
    const [select, setSelect] = useState(0)
    const changeSelect = (e: any) => {
        setSelect(e.target.value)
    }
    return <div className="fixed inset-0 z-modal bg-black/70 overflow-auto no-scrollbar overscroll-none"
                style={{left: '0px', opacity: 1, display: visible ? 'block' : 'none'}}>
        <div className="flex-center" style={{height: 'calc(100% + 1px)'}}>
            <div
                className="relative w-full mx-5 py-6 bg-layer2 rounded-3 overflow-hidden s768:w-[420px] s1366:w-[680px]">
                <div className="px-6 pb-5 text-20 font-500 text-primary capitalize">
                    <div className="flex items-center gap-2.5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path
                                d="M13.2194 4.32189C13.6103 4.54318 13.9336 4.8664 14.1549 5.25736L20.6761 16.7804C21.3496 17.9707 20.9309 19.4817 19.7406 20.1559C19.3683 20.3662 18.9483 20.4771 18.5206 20.4771H5.4782C4.11048 20.4771 3.00146 19.3681 3.00146 18.0004C3.00146 17.5727 3.11179 17.1527 3.32275 16.7804L9.84395 5.25736C10.5175 4.06706 12.0291 3.64771 13.2194 4.32189ZM11.9878 16.0914C11.3949 16.0914 10.9136 16.583 10.9136 17.1901C10.9136 17.7972 11.3942 18.2888 11.9878 18.2888C12.5807 18.2888 13.062 17.7972 13.062 17.1901C13.062 16.583 12.5813 16.0914 11.9878 16.0914ZM11.9878 8.76699C11.3949 8.76699 10.9136 9.24763 10.9136 9.84117V14.285C10.9136 14.8779 11.3942 15.3592 11.9878 15.3592C12.5813 15.3592 13.062 14.8785 13.062 14.285V9.84117C13.062 9.24828 12.5813 8.76699 11.9878 8.76699Z"
                                fill="#FF9820"></path>
                        </svg>
                        <span>Risk Warning</span></div>
                </div>
                <Close onCancel={onCancel}/>
                <div
                    className="px-6 pb-2 max-h-[60vh] overflow-auto overscroll-none no-scrollbar text-secondary text-12 font-500">
                    <div className="text-secondary text-14 font-500">
                        <div className="mb-5">Cryptocurrency trading carries a high level of risk and you may lose all
                            your funds.
                        </div>
                        <div className="mb-5">Please be mindful that the higher the multiplier you use, the more risky
                            the bet will be. But in any circumstances, you cannot lose more than your wager.
                        </div>
                        <div className="mb-5">Keep your bet open for longer than 8 hours and you might incur or receive
                            hourly funding payments, depending on market conditions. When the market is bullish, the
                            funding rate is positive and long traders pay short traders. When the market is bearish, the
                            funding rate is negative and short traders pay long traders.
                        </div>
                        <div className="text-warn mb-5">Funding payments are paid directly from your balance. Make sure
                            you have enough to cover your bets if you're planning to have them open for longer than 8
                            hours. Otherwise, your bets might get closed automatically.
                        </div>
                        <div className="mb-5">*Crypto trading is not gambling, and therefore not covered by our gaming
                            license. Utilising multiple accounts to circumvent maximum position size is strictly
                            prohibited and can result in account penalties.
                        </div>
                        <div className="mb-5 flex s768:gap-2"><span className="font-600">MORE: </span><span
                            className="text-up font-600 hover:underline cursor-pointer">How It Works</span><span
                            className="text-up font-600 hover:underline cursor-pointer">ROI calculator</span><span
                            className="text-up font-600 hover:underline cursor-pointer">Price Formulation</span></div>
                        <div className="mb-4">
                            <label
                                className="cursor-pointer inline-flex items-center gap-2 accent-brand">
                                <input
                                    className="relative outline-none cursor-pointer size-5 rounded-1.5 border-[#697072] border bg-transparent checked:bg-up checked:border-transparent checked:after:content-['✔'] checked:after:text-black checked:after:abs-center"
                                    type="checkbox" style={{appearance: 'none'}}/>
                                <span className="text-14 text-primary">DON’T SHOW AGAIN</span>
                            </label>
                        </div>
                        <div className="text-left">
                            <button
                                onClick={() => {
                                    onCancel()
                                }}
                                className="inline-flex items-center justify-center max-w-full rounded-1.5 active:translate-y-px hover:brightness-95 outline-none transition-all shadow whitespace-nowrap disabled:pointer-events-none disabled:opacity-50 bg-brand text-primary_brand font-700 h-9 text-12 p-3 uppercase">I
                                acknowledge
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}
export default RiskWarning
