import React, {useState} from "react";
import {Tab, TabGroup, TabList} from "@headlessui/react";
import TradeFilter from "./TradeFilter";
import OrderPage from "./OrderPage";
import {useMediaQuery} from "react-responsive";
import {useRecoilValue} from "recoil";
import {gameTypeState} from "../store/game";
import {TABLE_FIELDS} from "../constant/fields";

const TradeOrder = () => {
    const gameType = useRecoilValue<string>(gameTypeState)
    const menus = [{
        id: '1',
        name: 'active-bets',
        title: 'Active Bets',
        refresh: true,
        calc(record: any) {
            const time = parseInt(`${(new Date().getTime() - record.createdAtTimestamp) / 1000}`)
            const oldFlag = record.flag||false
            const newFlag = time >= record.period
            const period = record.period
            record.flag = newFlag
            record.currentTime = time > period ? 0 : (period - time)
            record.totalTime = period
            return !oldFlag && newFlag
        },
        params: {calcFlag: false, type: 1}
    }, {
        id: '2',
        name: 'closed-bets',
        title: 'CloseD Bets',
        params: {calcFlag: true, type: 2}
    }, {
        id: '3',
        name: 'public-bets',
        title: 'Public Bets',
        filter() {
            return gameType === "Updown"
        },
        params: {calcFlag: true, type: 3}
    }, {
        id: '4',
        name: 'leaderboard',
        title: 'LEADERBOARD',
        params: {calcFlag: true, type: 4}
    }]
    const [focus, setFocus] = useState(menus[0])
    const fieldInfo = {fields: TABLE_FIELDS[gameType]?.[focus.id], refresh: focus.refresh, calc: focus.calc}
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 768px)'})
    const groupClass = isTabletOrMobile ? "relative flex gap-3 s768:gap-3 s1024:gap-10 s1366:gap-25 s1920:gap-50 mb-2 flex-col items-start" : "relative flex items-center gap-3 s768:gap-3 s1024:gap-10 s1366:gap-25 s1920:gap-50 mb-2"
    const tabClass = isTabletOrMobile ? "relative text-secondary font-600 p-1 rounded-3 flex text-10 self-stretch bg-layer4 light:darkness" :
        "relative text-12 text-secondary font-600 inline-flex p-1 rounded-3 bg-layer4 light:darkness"
    // relative outline-none py-3 transition-colors duration-300 z-20 flex-1 px-0 leading-none
    return <div className="flex-1 card-container">
        <TabGroup
            className={groupClass}>
            <TabList
                className={tabClass}>
                {
                    menus.filter(it => !(it.filter?.())).map((item, index) => (
                        <Tab key={`${item.name}-menu-${index}`} onClick={() => {
                            setFocus(item)
                        }}
                             className={`${focus.id === item.id ? 'text-primary font-700' : ''} relative outline-none ${isTabletOrMobile ? 'p-1 flex-1' : 'px-5 py-3'} transition-colors duration-300 z-20`}>
                            {focus.id === item.id ? <div className="absolute inset-0 z-0 rounded-2 bg-tab_selected"
                                                         style={{
                                                             transform: 'none',
                                                             transformOrigin: '50% 50% 0px',
                                                             opacity: 1
                                                         }}/> : <></>}
                            <div className="relative z-10">{item.title}</div>
                        </Tab>))
                }
            </TabList>
            <TradeFilter/>
        </TabGroup>
        <OrderPage params={focus.params} fieldInfo={fieldInfo}/>
        <div><span aria-hidden="true" id="headlessui-tabs-panel-:rm:" role="tabpanel"
                   style={{
                       position: 'fixed',
                       top: '1px',
                       left: '1px',
                       width: '1px',
                       height: '0px',
                       padding: '0px',
                       margin: '-1px',
                       overflow: 'hidden',
                       clip: 'rect(0px, 0px, 0px, 0px)',
                       whiteSpace: 'nowrap',
                       borderWidth: '0px'
                   }}></span><span
            aria-hidden="true" id="headlessui-tabs-panel-:rn:" role="tabpanel"
            aria-labelledby="headlessui-tabs-tab-:rj:"
            style={{
                position: 'fixed',
                top: '1px',
                left: '1px',
                width: '1px',
                height: '0px',
                padding: '0px',
                margin: '-1px',
                overflow: 'hidden',
                clip: 'rect(0px, 0px, 0px, 0px)',
                whiteSpace: 'nowrap',
                borderWidth: '0px'
            }}></span>
            <div id="headlessui-tabs-panel-:ro:" role="tabpanel"
                 data-headlessui-state="selected"
                 aria-labelledby="headlessui-tabs-tab-:rk:">
                <div className="relative min-h-50 max-h-[inherit]">

                </div>
            </div>
            <span aria-hidden="true" id="headlessui-tabs-panel-:rp:" role="tabpanel"
                  aria-labelledby="headlessui-tabs-tab-:rl:"
                  style={{
                      position: 'fixed',
                      top: '1px',
                      left: '1px',
                      width: '1px',
                      height: '0px',
                      padding: '0px',
                      margin: '-1px',
                      overflow: 'hidden',
                      clip: 'rect(0px, 0px, 0px, 0px)',
                      whiteSpace: 'nowrap',
                      borderWidth: '0px'
                  }}></span>
        </div>
    </div>
}
export default TradeOrder
