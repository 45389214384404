import React, {useEffect, useRef, useState} from "react";
import initChart, {setSeriesData} from "../chart";
import {
    Listbox,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
    Popover,
    PopoverButton,
    PopoverPanel
} from "@headlessui/react";
import ArrowTop from "./ArrowTop";
import ArrowDown from "./ArrowDown";
import Setting from "./Setting";
import HistoryButton from "./HistoryButton";
import {useRecoilState, useRecoilValue} from "recoil";
import NumberDownOrUp from "./NumberDownOrUp";
import {kline} from "../api";
import {addCallback, delCallback, initSocket} from "../store/socket";
import {useMediaQuery} from "react-responsive";
import {cryptosState, selectCryptoState} from "../store/crypto";
import TextFormat from "./TextFormat";
import {CryptoVo} from "../constant/fields";
import {cryptoFlagState} from "../store/system";
import {showNumber6} from "../util/number";
import DataLoading from "./DataLoading";

let maxMillTime = 0
const Kline = ({hideHeader, onLoad}: { hideHeader?: boolean, onLoad?: (flag: boolean) => void }) => {
    const [open,] = useState(false)
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const cryptos = useRecoilValue<CryptoVo[]>(cryptosState)
    const cryptoFlag = useRecoilValue(cryptoFlagState)
    const klineRef = useRef<any>()
    const chartRef = useRef<any>();
    const [selectCrypto, setSelectCrypto] = useRecoilState<CryptoVo | null>(selectCryptoState)
    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1080px)'})
    const types = [
        {id: 1, name: 'tick'},
        {id: 2, name: '1m'},
        {id: 3, name: '5m'},
        {id: 4, name: '1h'},
        {id: 5, name: '4h'},
        {id: 5, name: '1d'},
    ]
    const [, setSeries] = useState<any>(null)
    const [selectedType, setSelectedType] = useState(types[0])
    const mapQuote = (quote: any) => ({
        DT: new Date(quote.timestampMillis),
        Open: parseFloat(quote.open),
        Close: parseFloat(quote.close),
        High: parseFloat(quote.high),
        Low: parseFloat(quote.low),
        Volume: parseInt(quote.value, 10),
        value: parseFloat(quote.close),
        time: quote.timestampMillis / 1000 // dayjs().format("HH:mm:ss")
    })

    let chart: any = null
    const queryKline = async () => {
        onLoad?.(true)
        setLoading(true)
        const data = await kline("BTCUSD")
        setData(data.data)
        onLoad?.(false)
        setLoading(false)
    }
    const initChartInfo = () => {
        if(!chart) {
            console.log("initChartInfo")
            chart = initChart()
        }
        initSocket()
        if (chart) {
            const seriesApi = setSeriesData(chart, data.map(mapQuote).reverse())
            if (seriesApi) {
                setSeries(seriesApi)
                addCallback("kline", (function (data: any) {
                    const timestampMillis = data.data?.timestampMillis
                    if (data.type === 'AGG' && maxMillTime < timestampMillis) {
                        maxMillTime = timestampMillis
                        seriesApi.update(mapQuote(data.data))
                        setSelectCrypto({
                            ...selectCrypto, ...data.data,
                            grow: data.data.close < (selectCrypto?.close || 0)
                        })
                    }
                }).bind(this))
            }
        }
    }
    useEffect(() => {
        if (chartRef.current) {
            initChartInfo()
        }
        return ()=>{
            delCallback("kline")
            chart?.remove()
            chart = null
        }
    }, [chartRef.current]);
    const init = async () => {
        await queryKline()
    }

    useEffect(() => {
            if (cryptoFlag) {
                init().then()
            }
            return () => {
                delCallback("kline")
                chart?.remove()
                chart = null
            }
        }, [cryptoFlag]
    )

    return <div
        className={`flex flex-col flex-1 ${isTabletOrMobile && hideHeader ? 'h-56' : 'min-h-96'} s1920:h-[560px] s2560:h-[810px] s3840:h-[1320px] relative card-container`}>
        {hideHeader ? <></> : <div className="flex justify-between" ref={klineRef}>
            <div className="s768:flex s768:items-center gap-4">
                <div className="relative">
                    <Popover className="relative">
                        <PopoverButton className="outline-none bg-transparent">
                            <div className="cursor-pointer">
                                <div className="flex items-center gap-1"><span
                                    className="block overflow-hidden size-6 s768:size-8 rounded-full lazy-load-image-background  lazy-load-image-loaded"><img
                                    alt=""
                                    src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                                    className="w-full h-full object-cover"/></span>
                                    <div
                                        className="text-primary text-20 s768:text-24 s1440:text-30">{selectCrypto?.pair}
                                    </div>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                         height="24" viewBox="0 0 24 24" fill="none"
                                         className="rotate-90">
                                        <path d="M10 7L15 12L10 17" stroke="currentColor"
                                              strokeWidth="2" strokeLinecap="round"
                                              strokeLinejoin="round"></path>
                                    </svg>
                                </div>
                            </div>
                        </PopoverButton>
                        <div>
                            {open ? <ArrowTop/> : <></>}
                            <PopoverPanel
                                data-open={open}
                                className="absolute z-40 bg-layer5 light:bg-white shadow rounded-2 text-secondary overflow-auto mt-[14px] p-2 s768:p-3 max-h-100">
                                <div className="w-80 text-14">
                                    <div className="grid grid-cols-3 text-secondary"
                                         style={{gridTemplateColumns: 'minmax(0px, 1fr) minmax(0px, 1fr) 80px'}}>
                                        <div className="pl-3 py-1">Pair</div>
                                        <div className="px-3 py-1 text-right">Price</div>
                                        <div className="pr-3 py-1 text-right">24h</div>
                                    </div>
                                    {cryptos?.map((item, index) => <div key={`${index}-crypto`}
                                                                        className="grid grid-cols-3 text-right bg-transparent cursor-pointer hover:bg-layer6 light:hover:bg-layer5 rounded"
                                                                        style={{gridTemplateColumns: 'minmax(0px, 1fr) minmax(0px, 1fr) 80px'}}>
                                        <div className="flex items-center pl-3 py-2"><span
                                            className="block overflow-hidden shrink-0 mr-1 w-5 h-5 rounded-full z-10 lazy-load-image-background  lazy-load-image-loaded"><img
                                            src="https://currency-trade.s3.ap-east-1.amazonaws.com/icons/BTC.png"
                                            alt=""
                                            className="w-full h-full object-cover"/></span>
                                            <div>{item.pair}</div>
                                        </div>
                                        <div
                                            className="text-primary px-3 py-2">{showNumber6(item?.close)}</div>
                                        <TextFormat value={(item.change || 0) * 100}/>
                                    </div>)}
                                </div>
                            </PopoverPanel>
                        </div>
                    </Popover>
                </div>
                <div
                    style={{
                        position: 'fixed',
                        top: '1px',
                        left: '1px',
                        width: '1px',
                        height: '0px',
                        padding: '0px',
                        margin: '-1px',
                        overflow: 'hidden',
                        clip: 'rect(0px, 0px, 0px, 0px)',
                        whiteSpace: 'nowrap',
                        borderWidth: '0px',
                        display: 'none'
                    }}></div>
                <NumberDownOrUp price={(selectCrypto?.close || 0)} down={(selectCrypto?.change || 0) > 0}/>
            </div>
            <div className="flex gap-2">
                <Listbox value={selectedType} onChange={setSelectedType}>
                    <div
                        className="relative min-w-min border border-input light:bg-white flex items-center px-3 s1366:px-4 text-12 h-10 s768:h-12 !h-8 bg-layer4 rounded-3 border-none">
                        <ListboxButton
                            className="bg-transparent w-full outline-none text-12 font-600 text-secondary flex items-center justify-between gap-1">
                            <div className="truncate text-12 capitalize text-primary">{selectedType.name}</div>
                            <ArrowDown/>
                        </ListboxButton>
                        <ListboxOptions as="ul"
                                        className="absolute left-0 top-full z-40 max-h-60 min-w-full mt-2 p-2 s1366:p-3 bg-layer5 light:bg-white shadow focus:outline-none rounded-2 overflow-auto no-scrollbar text-12 leading-4 text-secondary">
                            {types.map((type) => (
                                <ListboxOption key={type.id} value={type}
                                               className={`relative select-none px-2 py-1 mt-0 mb-2 last:mb-0 cursor-pointer w-full transition-all rounded ${selectedType.id === type.id ? 'bg-layer6 light:bg-layer5' : ''}`}>
                                        <span
                                            className="block text-12 capitalize font-700 text-primary">{type.name}</span>
                                </ListboxOption>
                            ))}
                        </ListboxOptions>
                    </div>
                </Listbox>
                <Setting/>
                <HistoryButton/>
            </div>
        </div>}
        {loading ? <DataLoading/> : <div className="w-full h-full flex-1 flex-col" id="chartContainer" ref={chartRef}>
        </div>}
    </div>
}

export default Kline
